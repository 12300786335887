<template>
  <md-dialog
    :md-active.sync="modal_opener"
    class="entity-selection-modal"
  >
    <md-dialog-title>
      {{ modal_title || $translate("choose_product_or_collection") }}
    </md-dialog-title>

    <div v-if="get_all_products.length || collections.length">
      <div v-if="enable_collection_selection">
        <span class="md-body-2 entity-selection-modal__title">{{ $translate("products.collections.available_collections") }}:</span>
        <md-tabs @md-changed="index => { $emit('collection_selected', index); selected_collection_index = index }">
          <template slot="md-tab" slot-scope="{ tab }">
            {{ tab.label }}
            <span v-if="tab.data.parent_collection && get_curly_brace_content(tab.data.parent_collection)">
              {{ $translate("products.parent_collections.parent") }}: {{ get_curly_brace_content(tab.data.parent_collection)[1] }}
            </span>
          </template>

          <md-tab
            v-for="(collection, index) in collections"
            :key="`collection-${index}`"
            :md-template-data="{ parent_collection: replace_dashes_for_spaces(decode(get_correct_translation_value(collection, 'body_html'))) }"
            :id="`${index}`"
            :md-label="get_correct_translation_value(collection, 'title')"
          />
        </md-tabs>

        <div
          v-if="selected_collection_index !== null && (collections[selected_collection_index] || is_selecting_for_collection)"
          class="entity-selection-modal__products"
        >
          <div
            v-if="
              !is_selecting_for_collection &&
              selected_collection_index &&
              get_correct_translation_value(collections[selected_collection_index], 'body_html') &&
              enable_product_selection
            "
          >
            <div class="md-body-2 entity-selection-modal__title">{{ $translate("products.parent_collections.parent") }}:</div>
            <div class="entity-selection-modal__items">
              <md-button
                data-cy="parent-category"
                class="md-raised"
                @click="entity_selected(selected_collection_index, false, undefined, true)"
              >
                {{ decode(get_curly_brace_content(get_correct_translation_value(collections[selected_collection_index], 'body_html'))[1]) }}
              </md-button>
            </div>
          </div>


          <div
            v-if="
              !is_selecting_for_collection &&
              selected_collection_index &&
              get_correct_translation_value(collections[selected_collection_index], 'title') !== empty_collections_title"
          >
            <div class="md-body-2 entity-selection-modal__title">{{ $translate("products.collections.collection") }}:</div>
            <div class="entity-selection-modal__items">
              <div
                data-cy="collection-modal"
                class="entity-preview entity-selection-modal__entity md-elevation-3"
                :class="{
                  'entity-preview--not-selectable': collection_has_only_unavailable_products(
                    is_selecting_for_collection ? 0 : selected_collection_index
                  )
                }"
                @click="!collection_has_only_unavailable_products(
                    is_selecting_for_collection ? 0 : selected_collection_index
                ) && entity_selected(undefined, true)"
              >
                <span
                  v-if="collection_has_only_unavailable_products(
                    is_selecting_for_collection ? 0 : selected_collection_index
                  )"
                >{{ $translate("products.collection_has_no_available_products") }}</span>
                <img :src="collections[selected_collection_index].image || default_image_src">
                <p>{{ get_correct_translation_value(collections[selected_collection_index], 'title') }}</p>
              </div>
            </div>
          </div>

          <div v-if="!is_selecting_for_parent_collection && enable_product_selection">
            <div class="md-body-2 entity-selection-modal__title">{{ $translate("products.title") }}:</div>
            <div class="entity-selection-modal__items">
              <div
                v-for="(product, index) in collections[is_selecting_for_collection ? 0 : selected_collection_index].products"
                :key="`banner-product-${index}`"
                class="entity-preview entity-selection-modal__entity md-elevation-3"
                :class="{ 'entity-preview--not-selectable': is_product_already_selected(product.buffer_id || product.id) || !product.published_at }"
                @click="() => (!is_product_already_selected(product.buffer_id || product.id) && product.published_at) ? entity_selected(index) : null"
              >
                <span v-if="!product.published_at">{{ $translate("products.product_unavailable") }}</span>
                <img data-cy="link-to-collection-image" :src="get_correct_img_src(product)">
                <p>{{ get_correct_translation_value(product, 'title') }}</p>
              </div>
            </div>
          </div>
        </div>
        <md-checkbox
          data-cy="link-to-microsite"
          v-if="index_or_id_of_selected_entity === undefined && enable_direct_link_selection"
          v-model="should_link_to_microsite"
          class="md-primary entity-selection-modal__checkbox"
        >
          {{ $translate("others.product_selection.checkbox") }}
        </md-checkbox>
      </div>
      <div v-else-if="enable_product_selection">
        <div class="entity-selection-modal__products">
          <div class="md-body-2 entity-selection-modal__title">{{ $translate("products.title") }}:</div>

          <div
            v-for="(product) in get_all_products"
            class="entity-preview entity-selection-modal__entity md-elevation-3"
            :class="{
              'entity-preview--not-selectable': !product.published_at,
              'entity-preview--selected': product.is_product_selected
            }"
            :key="product.id"
            @click="() => (
              !is_product_already_selected(product.buffer_id || product.id) &&
              product.published_at
            ) ? entity_selected(undefined, undefined, product.buffer_id || product.id) : null"
          >
            <span v-if="!product.published_at">{{ $translate("products.product_unavailable") }}</span>
            <img data-cy="select-product-image" :src="get_correct_img_src(product)" :alt="get_title(product)">
            <p>{{ get_title(product) }}</p>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="md-body-2">{{ $translate("no_products_or_collections") }}</p>

    <md-dialog-actions :class="{ 'entity-selection-modal__close': get_all_products.length || collections.length }">
      <md-button data-cy="closeModal" class="md-raised md-primary" @click="$emit('toggle_modal', false)">{{ $translate("close") }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import html_enity_encoder from "urlencode"
import { PRODUCTS_STORE, CONTENT_STORE, CONFIGURATION_STORE, shopify, imported, ADMIN, ADMIN_STORE } from "../../constants/others_constants"
import get_curly_brace_content from "../../../Shared/methods/get_curly_brace_content"
import replace_dashes_for_spaces from "../../../Shared/methods/replace_dashes_for_spaces"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"
import { FETCH_IMPORTED_PRODUCTS } from "../../stores/Admin/products/constants"
import { FETCH_SHOPIFY_DATA, UPDATE_LOADER } from "../../stores/Admin/constants"
import get_correct_first_image from "../../../Shared/methods/get_correct_first_image"
import { default_image_src } from "../../../Shared/constants/other"

export default {
  props: {
    modal_title: {
      type: String,
      required: false,
      default: ""
    },
    open_modal: {
      type: Boolean,
      required: false,
      default: false
    },
    index_or_id_of_selected_entity: {
      type: Number | Array,
      required: false,
      default: undefined
    },
    index_of_selected_header_item: {
      type: Number,
      required: false,
      default: undefined
    },
    enable_collection_selection: {
      type: Boolean,
      required: false,
      default: true
    },
    enable_product_selection: {
      type: Boolean,
      required: false,
      default: true
    },
    enable_direct_link_selection: {
      type: Boolean,
      required: false,
      default: true
    },
    send_full_data: {
      type: Boolean,
      required: false,
      default: false
    },
    should_fetch_data: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      selected_collection: 0,
      should_link_to_microsite: false,
      empty_collections_title: this.$translate("products.parent_collections.without_collection"),
      default_image_src
    }
  },
  computed: {
    ...mapState(CONTENT_STORE, ["header_items"]),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
      "shopify_collections",
      "shopify_products"
    ]),
    ...mapState(ADMIN_STORE, [
      "admin_language"
    ]),
    ...mapState(CONFIGURATION_STORE, ["shopify_config"]),
    products_type() {
      return this.shopify_config.should_use_shopify_data ? shopify : imported
    },
    is_selecting_for_collection() {
      return !isNaN(this.index_or_id_of_selected_entity)
    },
    is_selecting_for_parent_collection() {
      return this.index_or_id_of_selected_entity && isNaN(this.index_or_id_of_selected_entity)
    },
    selected_collection_index: {
      get() {
        return this.is_selecting_for_collection ?
          this.index_or_id_of_selected_entity :
          this.selected_collection
      },
      set(index) { this.selected_collection = index }
    },
    products_without_collection() {
      return (this[`${this.products_type}_products`] || []).filter(({ collections }) => !collections || !collections.length)
    },
    get_all_products() {
      return (this[`${this.products_type}_products`] || []).map(product => {
        const is_product_selected = Array.isArray(this.index_or_id_of_selected_entity) ?
          this.index_or_id_of_selected_entity.includes(product.buffer_id || product.id) :
          this.index_or_id_of_selected_entity === (product.buffer_id || product.id)

        return {
          ...product,
          is_product_selected
        }
      })
    },
    collections() {
      const all_collections = [
        ...(this[`${this.products_type}_collections`] || []),
        ...(
          this.products_without_collection.length ?
          [{
            title: this.empty_collections_title,
            products: this.products_without_collection
          }] : []
        )
      ]

      // If a specific menu item collection is selected, show only it
      if (this.index_or_id_of_selected_entity !== undefined && this.is_selecting_for_collection)
        return [all_collections[this.index_or_id_of_selected_entity]]

      // If an array of collections under parent collection is selected, filter out those none selected
      return this.index_or_id_of_selected_entity ?
        all_collections.filter((_, ind) => this.index_or_id_of_selected_entity.includes(ind)) :
        all_collections
    },
    modal_opener: {
      get() { return this.open_modal },
      set(val) { return this.$emit("toggle_modal", val) }
    }
  },
  watch: {
    async open_modal() {
      if (
        !this[`${this.products_type}_products`] &&
        this.should_fetch_data
      ) {
        this.update_loader()

        await this[`fetch_${this.products_type}_products`]()

        this.update_loader(false)
      }

      this.should_link_to_microsite = false
      this.selected_collection = 0
    }
  },
  methods: {
    ...mapMutations(ADMIN, {
      update_loader: UPDATE_LOADER
    }),
    ...mapActions(PRODUCTS_STORE, {
      fetch_imported_products: FETCH_IMPORTED_PRODUCTS
    }),
    ...mapActions(ADMIN, {
      fetch_shopify_products: FETCH_SHOPIFY_DATA,
    }),
    get_curly_brace_content,
    decode: html_enity_encoder.decode,
    replace_dashes_for_spaces,
    get_correct_translation_value,
    get_title(product) {
      return get_correct_translation_value(product, "title", [this.admin_language])
    },
    get_correct_img_src(product) {
      return get_correct_first_image(product)
    },
    collection_has_only_unavailable_products(collection_index) {
      return (this.collections[collection_index].products || []).every(({ published_at }) => !published_at)
    },
    entity_selected(index, selecting_collection, product_id, selecting_parent_collection) {
      if (product_id && !this.send_full_data) return this.$emit("entity_selected", product_id)

      const collection = this.collections[this.selected_collection]
      const product = !selecting_parent_collection && !selecting_collection ?
        this.get_all_products.find(({ id, buffer_id }) => (
          collection.products[index][this.shopify_config.should_use_shopify_data ? "buffer_id" : "id"] || product_id
        ) === (buffer_id || id)) : undefined

      this.$emit(
        "entity_selected",
        collection,
        product,
        this.should_link_to_microsite,
        selecting_collection,
        selecting_parent_collection
      )
    },
    is_product_already_selected(handle) {
      return (this.index_of_selected_header_item === undefined || !this.header_items[this.index_of_selected_header_item].sub_items) ?
        false : this.header_items[this.index_of_selected_header_item].sub_items.some(({ sub_category }) => sub_category === handle)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_admin-constants" as *;
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/admin" as *;

  .entity-selection-modal {
    padding: 0 $default-size;
    transform: translate(calc(-50% - .5px),calc(-50% - .5px)); // To get rid of font blur
    -webkit-font-smoothing: antialiased;
    
    .md-dialog-title {
      padding-left: 0;
    }

    &__items {
      padding-left: $half-default-size;
    }

    &__title {
      margin-bottom: $half-default-size;
    }
    
    &__entity {
      position: relative;

      span {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($black--light, 0.6);
        text-align: center;
        font-weight: bold;
        padding: $default-size 5px 0;
        color: $pure-white;
      }
    }

    &__products {
      max-height: 400px;
      overflow-y: auto;
      min-height: 70px;
      margin: $default-size 0;

      .md-button {
        margin: 0 $default-size $half-default-size 0;
      }
    }

    .md-tabs {
      display: block;
      overflow-x: auto;
      margin-bottom: $default-size;
    }

    .md-tabs-navigation {
      display: block;
      white-space: nowrap;
    }

    .md-layout-item {
      margin: 16px;
    }

    .md-ripple {
      padding: 0 $half-default-size;
    }

    .md-tab-nav-button {
      position: relative;
      height: 55px;
      min-width: 100px;
      padding: 0 $half-default-size;
      box-sizing: border-box;

      &:after {
        position: absolute;
        top: 15%;
        right: 0;
        height: 90%;
        width: 1px;
        content: "";
        background: $pure-white;
        background: linear-gradient(to bottom, $pure-white 0%, $blue 50%, $pure-white 100%);
      }

      &:nth-last-child(2):after {
        display: none !important;
      }

      span {
        position: absolute;
        bottom: 3px;
        left: 0;
        font-size: 9px;

        @media (max-width: $tablet) {
          display: block;
          position: relative;
          margin-top: 5px;
        }
      }
    }

    &__close {
      position: absolute;
      bottom: $half-default-size;
      right: $half-default-size;
    }
  }
</style>
